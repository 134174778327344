<script setup>
const props = defineProps({
  gradientId: {
    type: String,
    default: 'vehis_logo'
  }
})

</script>

<template>
  <svg width="253" height="40" viewBox="0 0 253 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M71.586 27.7182L80.2113 0.554688H88.2135L74.7413 39.4464H68.0115L54.5889 0.554688H62.995L71.586 27.7182Z" fill="black"/>
    <path d="M104.023 0.554688H129.46V7.29981H111.742V16.477H126.534V23.2049H111.742V32.7013H129.46V39.4464H104.023V0.554688Z" fill="black"/>
    <path d="M147.259 0.554688H155.044V16.4436H170.95V0.554688H178.752V39.4464H170.95V23.2049H155.044V39.4464H147.259V0.554688Z" fill="black"/>
    <path d="M237.923 33.0709C241.632 33.0709 244.567 31.6948 244.567 28.5739C244.567 20.6042 224.821 25.9561 224.821 11.9131C224.821 4.0606 230.944 0 238.444 0C243.309 0 248.21 1.72866 251.816 4.58091L248.426 9.83183C245.473 7.81821 241.867 6.76226 238.88 6.76226C235.29 6.76226 232.487 8.30516 232.487 11.3089C232.487 19.2624 252.403 13.5741 252.403 27.9706C252.403 36.0566 246.128 40 238.292 40C232.587 40 227.689 37.8863 223.695 34.631L227.136 29.2295C230.591 31.7454 234.3 33.0709 237.923 33.0709Z" fill="black"/>
    <path d="M206.515 0.554688H198.711V39.4464H206.515V0.554688Z" fill="black"/>
    <path d="M39.9991 0H0V39.9991H39.9991V0Z" fill="#473A4E"/>
    <path d="M39.9997 5.31641C20.8753 5.31641 5.31641 20.8753 5.31641 39.9997H40.0006L39.9997 5.31641Z" :fill="`url(#${props.gradientId})`"/>
    <defs>
      <linearGradient :id="props.gradientId" x1="45.0379" y1="10.0207" x2="10.8574" y2="45.043" gradientUnits="userSpaceOnUse">
        <stop stop-color="#DB1A21"/>
        <stop offset="0.5277" stop-color="#5E0000"/>
        <stop offset="1" stop-color="#483A4F"/>
      </linearGradient>
    </defs>
  </svg>

</template>
